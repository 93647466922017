import { API } from 'aws-amplify';
import {
  daysBetween,
  getLocalDateTime,
  secondsBetween,
} from '../../../common/Utilities';
import moment from 'moment';

const orgMemberQuery = `
  query MyQuery($userID: ID!, $orgID: ID!) {
    OrgMemberByUser(userID: $userID, orgID: { eq: $orgID }) {
      items {
        orgPoints
        lifetimeOrgPoints
      }
    }
  }
`;

const newCircleMemberRewardQuery = `
query ListCircleMembers(
  $userID: ID = "a8b8e888-a0cf-4a2d-9929-935428fb8010"
  $startBefore: String!
  $endAfter: String!
) {
  circleMemberByUserID(userID: $userID, limit: 10000) {
    items {
      circle {
        rewards(
          filter: {
            launchDate: { le: $startBefore }
            validTo: { ge: $endAfter }
          }
        ) {
          items {
            isDeleted
            approverType
            cost
            costType
            description
            id
            imageName
            imageType
            isActive
            launchDate
            limitCount
            location
            name
            ownerID
            ownerType
            personLimit
            remainingCount
            tags
            rewardType
            validFrom
            validTo
            value
            valueType
            createdBy
            orgID
            tangoAccountID
          }
        }
      }
    }
  }
}
`;

const getRewards = async (args: {
  userID: string;
  orgID: string;
  startBefore: string;
  endAfter: string;
}) => {
  const { orgID, userID, endAfter, startBefore } = args;

  const orgMemberQueryRes = await API.graphql({
    query: orgMemberQuery,
    variables: {
      userID,
      orgID,
      limit: 100000,
    },
  });
  const orgPoints =
    // @ts-ignore
    orgMemberQueryRes.data.OrgMemberByUser.items[0].orgPoints;
  const lifeTimeOrgPoints =
    // @ts-ignore
    orgMemberQueryRes.data.OrgMemberByUser.items[0].lifeTimeOrgPoints;

  const listCircleMembersRes = await API.graphql({
    query: newCircleMemberRewardQuery,
    variables: {
      userID,
      startBefore,
      endAfter,
    },
  });
  const circleMembers =
    // @ts-ignore
    listCircleMembersRes.data.circleMemberByUserID.items;

  const ourCircles = [];
  const ourRewards = [];
  // let _customRewardCount = 0;

  for (const circleMember of circleMembers) {
    const circle = circleMember.circle;
    if (circle == null) {
      continue;
    }
    const rewards = circle.rewards.items;

    for (const reward of rewards) {
      if (!reward.isDeleted) {
        const now = getLocalDateTime();
        const validFrom = new Date(reward.validFrom);
        const gmtValidToDate = new Date(reward.validTo);
        const validTo = moment(gmtValidToDate)
          .add(1, 'day')
          .subtract(1, 'second');

        reward.remaingDaysAvailable = daysBetween(now, validTo);
        reward.daysBeforeAvailable = daysBetween(now, validFrom);
        reward.secondsBeforeStart = secondsBetween(now, validFrom);
        reward.remainingSecondsInMission = secondsBetween(now, validTo);

        reward.myCirclePoints = orgPoints;
        reward.purchaseCount = 0;
        reward.isVisible = true;
        if (reward.isVisible) {
          ourRewards[ourRewards.length] = reward;
        }
        if (reward.utid !== '') {
          // _customRewardCount += 1;
        }
      }
      ourCircles[0] = {
        name: '',
        circlePoints: orgPoints,
        id: '',
        isActive: true,
        lifetimeCirclePoints: lifeTimeOrgPoints,
        memberType: '',
        status: '',
        rewards: ourRewards,
      };
    }
  }

  return ourCircles[0].rewards;
};

export default getRewards;
