import { API } from 'aws-amplify';
import gql from 'graphql-tag';

export default async function fetchDealTypes() {
  let stageCategoriesNextToken = null;
  const stageCategories = [];
  let defaultCategoryID;

  const getCategoryIds = gql`
    query MyQuery($orgID: ID!, $nextToken: String) {
      listStageCategorys(
        filter: { orgID: { eq: $orgID } }
        nextToken: $nextToken
      ) {
        items {
          id
          title
          enableDeal
        }
        nextToken
      }
    }
  `;

  do {
    const getCategoryIdsRes = await API.graphql({
      query: getCategoryIds,
      variables: {
        orgID: global.me.orgID,
        nextToken: stageCategoriesNextToken,
      },
    });

    stageCategories.push(...getCategoryIdsRes.data.listStageCategorys.items);
    stageCategoriesNextToken =
      getCategoryIdsRes.data.listStageCategorys.nextToken;

    // console.log('getCategoryIdsRes: ', getCategoryIdsRes);
    // console.log('global me orgId: ', global.me.orgID);
    // console.log('stageCategoriesNextToken: ', stageCategoriesNextToken);
  } while (stageCategoriesNextToken);

  if (stageCategories.length > 0) {
    for (let i = 0; i < stageCategories.length; i++) {
      if (stageCategories[i].enableDeal) {
        defaultCategoryID = stageCategories[i].id;
        break;
      }
    }
  }

  // Normalize stageCategories with enabledDeal === true by id for easier selection in kanban/list
  const filteredStageCategories = stageCategories.filter(
    (stageCategory) => stageCategory.enableDeal
  );
  const normalizedStageCategories = filteredStageCategories.reduce(
    (acc, stageCategory) => {
      acc[stageCategory.id] = stageCategory;
      return acc;
    },
    {}
  );

  return [defaultCategoryID, normalizedStageCategories];
}
