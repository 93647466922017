import React, { useEffect, useState } from 'react';
import RewardsView from './Rewards.view';
import NewGameState from '../../_types/NewGameState';
import getRewards from '../../../games-helpers/getRewards';
import { useGetUserInformationQuery } from '../../../../../store/api/GraphQlQuery';
import { getLocalDate } from '../../../../../common/Utilities';
import RewardType from '../../_types/RewardType';

interface Props {
  newGameState: NewGameState;
  onChangeNewGameState: (newGameState: NewGameState) => void;
}

const RewardsContainer: React.FC<Props> = ({
  newGameState,
  onChangeNewGameState,
}) => {
  const { data, isLoading: isLoadingGetUserInformation } =
    useGetUserInformationQuery({});
  const [rewards, setRewards] = useState<RewardType[]>([]);

  useEffect(() => {
    const fetchRewards = async () => {
      if (!data || isLoadingGetUserInformation) return;

      const rewards = await getRewards({
        endAfter: getLocalDate(),
        orgID: data.me.orgID,
        startBefore: getLocalDate(),
        userID: data.me.id,
      });
      setRewards(rewards);
    };
    fetchRewards();
  }, [isLoadingGetUserInformation, data]);

  return (
    <RewardsView
      rewards={rewards}
      newGameState={newGameState}
      onChangeNewGameState={onChangeNewGameState}
    />
  );
};

export default RewardsContainer;
